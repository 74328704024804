import Vue from "vue";
import Router from "vue-router";
import DefaultLayout from "./views/layouts/DefaultLayout.vue";


Vue.use(Router);

const router = new Router({
    mode: "history",
    base: process.env.BASE_URL,
    routes: [
        {
            path: "/",
            redirect: "/login"
        },
        {
            path: "/login",
            name: "login",
            component: () =>
                import ("./views/auth/Login.vue")
        },
        {
            path: "/home",
            component: DefaultLayout,
            children: [
                {
                    path: "/home",
                    name: "home",
                    component: () =>
                        import ("./views/admin/Welcome.vue")
                },
                {
                    path: "/dashboard",
                    name: "dashboard",
                    component: () =>
                        import ("./views/pages/jobs/Index.vue")
                },
                {
                    path: "/create-job",
                    name: "create-job",
                    component: () =>
                        import ("./views/pages/jobs/Add.vue")
                },
                {
                    path: "/profile",
                    name: "profile",
                    component: () =>
                        import ("./views/user/Profile.vue")
                },
                {
                    path: "/change-password",
                    name: "change-password",
                    component: () =>
                        import ("./views/user/ChangePassword.vue")
                },
            ]
        },
        {
            path: "/404",
            name: "404",
            component: () =>
                import ("./views/errors/NotFound.vue")
        },
        {
            path: "*",
            redirect: "/404"
        }
    ]
});

router.beforeEach((to, from, next) => {
    const publicPages = ["/login"];
    const authRequired = !publicPages.includes(to.path);

    if (authRequired) {
        if ($cookies.isKey("user")) {
            const user = $cookies.get("user");
            if (user.access_token != (null || undefined)) {
                next();
            } else {
                return next("/login");
            }
        } else {
            return next("/login");
        }
    }
    next();
});
export default router;